const protectedRoutes = [
  '/action-center',
  '/ai/planner-assistant',
  '/carts/[cartId]/order',
  '/carts/[cartId]/plan',
  '/credit-entity/[hostCreditEntityId]/credit-entity-host-list',
  '/credit-entity/[hostCreditEntityId]/profile',
  '/host-events/[userEventId]/event-cart-list',
  '/host-events/[userEventId]/profile',
  '/host-events/create-event',
  '/hosts/[hostId]/host-event-list',
  '/hosts/[hostId]/profile',
  '/mb/planner-board',
  '/orders',
  '/orders/[orderId]/view',
  '/products/[productSlug]',
  '/search/navigation',
  '/search/product',
  '/search/product/list',
  '/tenders/[referenceId]/bid-responses',
  '/user-events/expired',
  '/user-events/new-assigned',
  '/user-events/outstanding-payment',
  // Experimental
  '/search/product/algolia/host-products-search',
  '/search/product/es/planner-products-search'
];

const isProtectedRoute = (route) => protectedRoutes.includes(route);

export default isProtectedRoute;
