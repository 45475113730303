import Image from 'next/image';
import { useRouter } from 'next/router';
import { Modal, ModalBody } from 'reactstrap';

import { CloseIcon, Text, TextWithIcon } from '@/components/atomic/nuclei';
import { mediaStoreBaseURL } from '@/config/common';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const SuccessModal = ({
  eventProfileDetail,
  eventSummaryList,
  onClickClose
}) => {
  const {
    id: userEventId,
    userEventNumber,
    userEventDetails: [
      {
        checkoutEvent: {
          iconUrl,
          event: { name: eventName }
        }
      }
    ]
  } = eventProfileDetail;
  const router = useRouter();

  return (
    <Modal
      centered
      size='lg'
      isOpen={true}
      backdrop={true}
      fade={false}
    >
      <ModalBody>
        <div className='flex flex-col gap-3 m-4 bg-white rounded-lg create-event-bg text-white md:mx-auto md:w-[720px]'>
          <div className='border-b border-white p-3'>
            <CloseIcon
              {...{
                icon: 'close-with-border-white.svg',
                onClick: onClickClose
              }}
            />
            <Text
              {...{
                className: 'text-2xl font-semibold text-center',
                content: '🎉 New event has been created successfully!'
              }}
            />
          </div>
          <div className='flex gap-2 items-center px-3'>
            <Image
              alt='event image'
              className='rounded-lg'
              height={0}
              src={`${mediaStoreBaseURL}/${iconUrl}`}
              style={{ height: 80, width: 80 }}
              unoptimized
              width={0}
            />
            <Text
              {...{
                className: 'text-lg font-semibold',
                content: eventName
              }}
            />
          </div>
          <div className='flex justify-between px-3'>
            <TextWithIcon
              {...{
                alt: 'event profile',
                className:
                  'shadow-none border border-white rounded-lg px-2 py-1 cursor-pointer',
                icon: 'view-icon.svg',
                iconHeight: 16,
                iconWidth: 16,
                label: 'Event',
                labelStyle: 'text-sm font-medium text-white',
                onClick: () => {
                  router.push(
                    getPageURL({
                      excludeBaseUrl: false,
                      pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
                      pathParams: {
                        userEventId
                      }
                    })
                  );
                }
              }}
            />
            <TextWithIcon
              {...{
                alt: 'user event number',
                className: 'shadow-none bg-white rounded-lg px-2 py-1',
                icon: 'user-event-brand-icon.svg',
                iconHeight: 16,
                iconWidth: 16,
                label: parseNumberedLabel(userEventNumber),
                labelStyle: 'text-sm font-medium text-dim-gray'
              }}
            />
          </div>
          <div className='flex flex-col gap-4 mt-1 px-3 pb-3'>
            {eventSummaryList.map(({ alt, icon, label }) => (
              <TextWithIcon
                key={`${label}-${icon}`}
                {...{
                  alt,
                  className: 'shadow-none',
                  icon,
                  iconHeight: 20,
                  iconWidth: 20,
                  label,
                  labelStyle: 'text-sm font-medium text-white'
                }}
              />
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessModal;
